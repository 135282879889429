import {
    Subscription,
    SubscriptionProps,
} from './components/subscription/Subscription';
import {
    ShorthandValue,
    SubscriptionProps as AriaSubscriptionProps,
    FooterNavigationProps as AriaFooterNavigationProps,
    logoClassName,
    FooterNavigation as AriaFooterNavigation,
    useTheme,
    useStyles,
    AppDisplayModeType,
    Caption,
} from '@archipro-design/aria';
import { SupportedCountry } from '@archipro-website/localisation';
import { RenderAnchorTag } from './types';
import { Location } from 'history';
import * as S from './styles';
import { useCountryContext } from '@archipro-website/localisation';

interface FooterNavigationProps {
    countryCode: SupportedCountry;
    subscription: Omit<SubscriptionProps, 'Component' | 'componentProps'>;
    renderAnchorTag: RenderAnchorTag;
    userCounter?: React.ReactNode;
    /**
     * The result of the useLocation hook.
     * Both apps have different versions of react-router
     * so we need to pass it in to avoid dependency resolution issues.
     */
    location: Location;
    appDisplayMode: AppDisplayModeType;
    footerSubscription?: React.ReactNode;
}

const DARK_AOC_LIST = ['home-design-event', 'advertising'];

export const AOC_BLACK_LIST = [
    'member',
    'search',
    'professionals',
    'articles',
    'projects',
    'products',
];

export const FooterNavigation = ({
    countryCode,
    subscription,
    renderAnchorTag,
    userCounter,
    location,
    appDisplayMode,
    footerSubscription,
}: FooterNavigationProps) => {
    const pathSegments = location.pathname.split('/');
    const excludeAoc = pathSegments[1]
        ? AOC_BLACK_LIST.includes(pathSegments[1])
        : false;
    const onDarkPage = pathSegments[1]
        ? DARK_AOC_LIST.includes(pathSegments[1])
        : false;
    const theme = useTheme();
    const { css } = useStyles({
        onDarkPage: onDarkPage,
        forceBackground: onDarkPage
            ? '#181616'
            : theme.siteVariables.colors.primitive.white,
    });

    const { byCountry } = useCountryContext();
    const acknowledgementOfCountry = byCountry(FOOTER_ACKNOWLEDGEMENT);

    return (
        <>
            {!excludeAoc &&
                acknowledgementOfCountry &&
                appDisplayMode.desktop && (
                    <div className={css(S.AOCSectionContainer)}>
                        <div className={css(S.AOCSection)}>
                            <Caption variant="03">
                                {acknowledgementOfCountry}
                            </Caption>
                        </div>
                    </div>
                )}
            {footerSubscription}
            <AriaFooterNavigation
                design={{
                    ...(location.pathname.search('ecommerce-launch') === 1 && {
                        // temp solution, remove the logo
                        [`& .${logoClassName}`]: {
                            opacity: '0 !important',
                        },
                    }),
                }}
                appDisplayMode={appDisplayMode}
                footerCopyRightsLinks={footerCopyrightItemsMap(
                    countryCode,
                    renderAnchorTag
                )}
                footerNavigationItem={footerLinksMap(
                    countryCode,
                    renderAnchorTag
                )}
                footerText={footerTextMap(countryCode)}
                subscription={
                    {
                        children: (Component, props) => {
                            return (
                                <>
                                    {userCounter || (
                                        <Subscription
                                            Component={Component}
                                            componentProps={props}
                                            {...subscription}
                                        />
                                    )}
                                </>
                            );
                        },
                        header: subscriptionTextMap(countryCode),
                    } as ShorthandValue<AriaSubscriptionProps>
                }
                variables={{
                    footerNavigationBackgroundColor: userCounter
                        ? '#181616'
                        : theme.siteVariables.colors.charcoal['000'],
                }}
            />
        </>
    );
};

export const FOOTER_ACKNOWLEDGEMENT = {
    AU: 'At ArchiPro we recognise and acknowledge the existing, original and ancient connection Aboriginal and Torres Strait Islander peoples have to the lands and waterways across the Australian continent. We pay our respects to the elders past and present. We commit to working together to build a prosperous and inclusive Australia.',
    NZ: '',
};

const footerCopyrightItemsMap = (
    countryCode: SupportedCountry,
    renderAnchorTag: RenderAnchorTag
): AriaFooterNavigationProps['footerCopyRightsLinks'] => {
    switch (countryCode) {
        case 'AU':
            return [
                {
                    children: 'Privacy',
                    ...renderAnchorTag(
                        'https://email.archipro.com.au/archipro-privacy-and-data-policy-aus'
                    ),
                },
                {
                    children: 'Terms',
                    ...renderAnchorTag(
                        'https://email.archipro.com.au/archipro-website-terms-of-use-aus'
                    ),
                },
            ];
        case 'NZ':
        default:
            return [
                {
                    children: 'Privacy',
                    href: 'https://email.archipro.co.nz/archipro-privacy-and-data-policy-nz',
                    ...renderAnchorTag(
                        'https://email.archipro.co.nz/archipro-privacy-and-data-policy-nz'
                    ),
                },
                {
                    children: 'Terms',
                    ...renderAnchorTag(
                        'https://email.archipro.co.nz/archipro-website-terms-of-use-nz'
                    ),
                },
            ];
    }
};

const footerTextMap = (
    countryCode: SupportedCountry
): AriaFooterNavigationProps['footerText'] => {
    switch (countryCode) {
        case 'AU':
        case 'NZ':
        default:
            return `Your Gateway to Architecture and Design for Products, Professionals and Projects`;
    }
};

const subscriptionTextMap = (
    countryCode: SupportedCountry
): AriaFooterNavigationProps['footerText'] => {
    switch (countryCode) {
        case 'AU':
        case 'NZ':
        default:
            return `Stay informed about the newest projects, products, and technologies shaping the future of the architecture and design environment.`;
    }
};

const discover = (
    renderAnchorTag: RenderAnchorTag,
    countryCode: SupportedCountry
) => {
    const results = [
        {
            content: {
                children: 'Projects',
                ...renderAnchorTag('/projects'),
            },
            key: 'projects',
        },
        {
            content: {
                children: 'Products',
                ...renderAnchorTag('/products'),
            },
            key: 'products',
        },
        {
            content: {
                children: 'Professionals',
                ...renderAnchorTag('/professionals'),
            },
            key: 'professionals',
        },
        {
            content: {
                children: 'Archizeen',
                ...renderAnchorTag('/articles'),
            },
            key: 'articles',
        },

        {
            content: {
                children: 'Digital Magazines',
                ...renderAnchorTag('/digital-magazines'),
            },
            key: 'digital-magazines',
        },
    ];
    if (countryCode === 'NZ') {
        results.push({
            content: {
                children: 'Building Guide',
                ...renderAnchorTag('/building-guide'),
            },
            key: 'building-guide',
        });
    }

    return results;
};

const archiPro = (renderAnchorTag: RenderAnchorTag) => [
    {
        content: {
            children: 'About',
            ...renderAnchorTag('/about-us'),
            target: '_blank',
        },
        key: 'about',
    },
];

const connect = (
    renderAnchorTag: RenderAnchorTag,
    countryCode: SupportedCountry
) => [
    {
        content: {
            children: 'Instagram',
            ...renderAnchorTag('https://www.instagram.com/archipro_/'),
        },
        key: 'instagram',
    },
    {
        content: {
            children: 'Facebook',
            ...renderAnchorTag(
                countryCode === 'AU'
                    ? 'https://www.facebook.com/archipro.australia'
                    : 'https://www.facebook.com/archipro/'
            ),
        },
        key: 'facebook',
    },
    {
        content: {
            children: 'Linkedin',
            ...renderAnchorTag('https://www.linkedin.com/company/archipro'),
        },
        key: 'linkedin',
    },
    {
        content: {
            children: 'YouTube',
            ...renderAnchorTag(
                'https://www.youtube.com/channel/UCw3LUhWr0j-z2rpqGfQK8Tg'
            ),
        },
        key: 'youtube',
    },
    {
        content: {
            children: 'Pinterest',
            ...renderAnchorTag('https://www.pinterest.com/ArchiPro/'),
        },
        key: 'pinterest',
    },
];

const business = (renderAnchorTag: RenderAnchorTag) => [
    {
        content: {
            children: 'Become a Professional',
            ...renderAnchorTag('/become-a-pro'),
            target: '_blank',
        },
        key: 'advertise',
    },
    {
        content: {
            children: 'For Suppliers & Brands',
            ...renderAnchorTag('/sell-on-archipro'),
            target: '_blank',
        },
        key: 'ecommerce',
    },
    {
        content: {
            children: 'Sell via ArchiPro',
            ...renderAnchorTag('/ecommerce-launch'),
        },
        key: 'sell-on-archipro',
    },
];

const footerLinksMap = (
    countryCode: SupportedCountry,
    renderAnchorTag: RenderAnchorTag
) => {
    switch (countryCode) {
        case 'AU':
            return [
                {
                    Discover: discover(renderAnchorTag, countryCode),
                },
                {
                    Connect: [
                        {
                            content: {
                                children: 'Home Design Evening',
                                ...renderAnchorTag('/home-design-event'),
                            },
                            key: 'mge',
                        },
                        ...connect(renderAnchorTag, countryCode),
                    ],
                },
                {
                    ArchiPro: [
                        ...archiPro(renderAnchorTag),
                        {
                            content: {
                                children: 'Careers',
                                ...renderAnchorTag(
                                    'https://apply.workable.com/archipro-3'
                                ),
                            },

                            key: 'careers',
                        },
                        {
                            content: {
                                children: 'Contact',
                                ...renderAnchorTag('/contact-us'),
                            },
                            key: 'contact',
                        },
                    ],
                },
                {
                    Business: business(renderAnchorTag),
                },
            ];

        case 'NZ':
        default:
            return [
                {
                    Discover: discover(renderAnchorTag, countryCode),
                },
                {
                    Connect: [
                        {
                            content: {
                                children: 'Home Design Evening',
                                ...renderAnchorTag('/home-design-event'),
                            },
                            key: 'mge',
                        },
                        ...connect(renderAnchorTag, countryCode),
                    ],
                },
                {
                    ArchiPro: [
                        ...archiPro(renderAnchorTag),
                        {
                            content: {
                                children: 'Careers',
                                ...renderAnchorTag(
                                    'https://apply.workable.com/archipro-3'
                                ),
                            },
                            key: 'careers',
                        },
                        {
                            content: {
                                children: 'Contact',
                                ...renderAnchorTag('/contact-us'),
                            },
                            key: 'contact',
                        },
                        {
                            content: {
                                children: 'Shop FAQs',
                                ...renderAnchorTag(
                                    'https://resources.archipro.co.nz/ecommerce-faqs'
                                ),
                            },
                            key: 'ecom-faqs',
                        },
                    ],
                },
                {
                    Business: business(renderAnchorTag),
                },
            ];
    }
};
